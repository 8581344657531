<template>
  <!--  -->
  <div class="fixed h-full w-full z-10 top-0 flex management_bg flex-col mt-4 overflow-scroll">
    <div class="top_section w-full">
      <div class="w-full flex justify-evenly">
        <h1 class="mx-auto dark_text_heading">ADD WELL</h1>
        <button class="bg-red-500 p-2 uppercase mr-2" @click="$emit('add_well_pop')">X</button>

      </div>

      <div class="w-full flex justify-start px-4">
        <button class=" dark_text p-2  mt-4 uppercase ml-2" :class="{ activenav: section == 1 }"
          @click="selectSection(1)">witsml</button>
        <button class=" dark_text p-2  mt-4 uppercase ml-2" :class="{ activenav: section == 2 }"
          @click="selectSection(2)">Flatfile</button>
        <button class=" dark_text p-2  mt-4 uppercase ml-2" :class="{ activenav: section == 3 }"
          @click="selectSection(3)">Wits0</button>
      </div>
    </div>

    <div v-if="section == 1" class="w-full mb-12 px-4 ">
      <div class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2">
        <div class="w-full mt-4 px-4">
          <form class="flex flex-wrap" @submit="$event.preventDefault()">
            <div class="w-1/4 my-1 p-2">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Customer Name</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg" v-model="this.$store.state.data.customer"
                @change="getEntityList">
                <option :value="this.$store.state.data.customer" selected>
                  {{ this.$store.state.data.customer }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="this.$store.state.data.customer">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select Source</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="well_info.source"
                @change="chooseSource">
                <option :value="sourceList" v-for="(sourceList, index) of getSourceList" :key="index">
                  {{ sourceList }}
                </option>
              </select>
            </div>
            <div class="w-1/5 my-1 p-2" v-if="well_info.source">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Enter Latitude</label>
              <input required type="text" v-model="well_info.latitude" id="latiInputId"  @input="validation($event, 1)" @change="checkWellInfo"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />
              <!-- <span class="show_hide icon is-small lasfile_btn validate_check">
                      <i class="fas fa-check"></i>
                    </span> -->
            </div>
            <div class="w-1/5 my-1 p-2" v-if="well_info.latitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Enter Longitude</label>
              <input required type="text" id="longiInputId" v-model="well_info.longitude" @change="checkWellInfo"  @input="validation($event, 2)"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />
              <!-- <span class="show_hide icon is-small lasfile_btn validate_check">
                      <i class="fas fa-check"></i>
                    </span> -->
            </div>
            <div class="w-1/8 my-1 p-2" v-if="well_info.latitude && well_info.longitude">
              <button @click="validate_latitude" class="toggleLabel p-1 px-2 rounded flex items-center mt-6">
                validate
              </button>
            </div>
            <div class="w-1/8 my-1 p-2" v-if="!wellmatched && well_info.latitude && well_info.longitude" >
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select TimeZone</label>
              <select class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="well_info.timeZone"
                @change="getTimeZoneList">
                <option v-for="(timeZone, i) in timeZoneListData" :key="i" :value="timeZone">
                  {{ timeZone }}
                </option>
              </select>
            </div>
            <div class="w-1/8 my-1 p-2" v-if="!wellmatched && well_info.timeZone && well_info.latitude && well_info.longitude">
              <label for="dstOffset" class="block uppercase text-xs font-light mb-2">DST Offset</label>
              <input required type="text" v-model="well_info.dstOffset" id="dstOffset"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />

            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.timeZone && well_info.latitude && well_info.longitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select WellName</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="sourceWell"
                @change="selectedWellName(sourceWell)">
                <option value=""  selected>
                  Select Well Name
                </option>
                <option :value="sourceWell" v-for="(sourceWell, index) of sourceWellData" :key="index" >
                  {{ sourceWell.wellname }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellName && well_info.latitude && well_info.longitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select Wellbore
                Name</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="wellbore"
                @change="selectedWellBore(wellbore)">
                <option value=""  selected>
                  Select Wellbore Name
                </option>
                <option :value="wellbore" v-for="(wellbore, index) of wellBoreList" :key="index">
                  {{ wellbore.wellborename }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellBoreName && well_info.latitude && well_info.longitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Enter Rigname</label>
              <input required type="text" id="longiInputId" v-model="well_info.rigName"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" />
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.rigName && well_info.latitude && well_info.longitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select Wellprofile</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="well_info.wellProfile">
                <option :value="wellProfile" v-for="(wellProfile, index) of wellProfileList" :key="index">
                  {{ wellProfile }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellProfile">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">SUB SURF LAT</label>
              <input required type="text" id="longiInputId" v-model="well_info.sublat"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" />
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellProfile">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">SUB SURF LONG</label>
              <input required type="text" id="longiInputId" v-model="well_info.sublong"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" />
            </div>
            <div class="w-1/4 my-1 p-2 flex" v-if="well_info.wellProfile && well_info.latitude && well_info.longitude">
              <div class="w-2/4">
                <label for="selectStream" class="block uppercase text-xs font-light mb-2">Select stream</label>
                <div class="flex mt-2">
                  <div class="flex items-center mr-4">
                    <input type="radio" value="live" v-model="well_info.wellstatus" name="streem" id="streemLive"
                      @click="onChangeSelection_Live_histo" :checked="well_info.wellstatus === 'LIVE'"
                      :disabled="well_info.wellstatus !== 'LIVE'" />
                    <label class="ml-2 uppercase text-xs font-light" for="streemLive"
                      :disabled="well_info.wellstatus !== 'LIVE'">Live</label>
                  </div>
                  <div class="flex items-center">
                    <input type="radio" value="Historical" v-model="well_info.wellstatus" name="streem" id="streemHist"
                      :checked="well_info.wellstatus === 'HISTORICAL'"
                      :disabled="well_info.wellstatus !== 'HISTORICAL'" />
                    <label class="ml-2 uppercase text-xs font-light" for="streemHist"
                      :disabled="well_info.wellstatus !== 'HISTORICAL'">HISTORICAL</label>
                  </div>
                </div>
              </div>
              <div class="w-2/4" v-if="well_info.wellstatus && well_info.latitude && well_info.longitude">
                <label for="drillingProfile" class="block uppercase text-xs font-light mb-2">Select Drilling
                  Profile</label>
                <div class="flex mt-2">
                  <div class="flex items-center mr-4">
                    <input type="radio" value="Offshore" v-model="well_info.drillProfile" name="drillingprofile"
                      id="Offshore" @click="onChangeSelection_Live_histo" />
                    <label class="ml-2 uppercase text-xs font-light" for="Offshore">Offshore</label>
                  </div>
                  <div class="flex items-center">
                    <input type="radio" value="Onshore" name="drillingprofile" v-model="well_info.drillProfile"
                      id="Onshore" />
                    <label class="ml-2 uppercase text-xs font-light" for="Onshore">Onshore</label>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="px-1 mt-4 flex justify-center shadow" v-if="well_info.source && well_info.timeZone && well_info.wellName && well_info.wellBoreName && well_info.rigName && well_info.wellProfile && well_info.wellstatus && well_info.drillProfile && well_info.latitude && well_info.longitude">
            <button v-if="!wellmatched && well_info.drillProfile" class="dark_text p-2  mt-4 uppercase ml-2 newSty_bg"
              @click="loader ? '' : submitWellData()">
              SUBMIT
            </button>
            <button class="dark_text p-2  mt-4 uppercase ml-2  shadow newSty_bg" @click="previewLocation()"
              v-if="well_info.latitude && well_info.longitude && !wellmatched">
              {{ !prevLocation ? 'Preview' : 'Hide' }} Location
            </button>
          </div>
          <div class="px-1 mt-4 flex justify-center" v-if="prevLocation">
            <iframe
              :src="`https://maps.google.com/maps?q=${well_info['latitude']}, ${well_info['longitude']}&z=15&output=embed`"
              height="400" frameborder="0" style="border: 0; width: 100%"></iframe>
          </div>
          <div class="px-1 mt-4 flex justify-center text-red-500" v-if="wellmatched">
            Above Entered Latitude & Longitude Details are Already added
            <span>
              <p class="curser-pointer ml-1 activenav text-xs uppercase" @click="previewLocation()">
                {{ !prevLocation ? 'View' : 'Close' }}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Flat file tab related start -->
    <div v-if="section == 2 || section == 3" class="w-full mb-12 px-4 ">
      <div class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2">
        <div class="w-full mt-4 px-4">
          <form class="flex flex-wrap" @submit="$event.preventDefault()">
            <div class="w-1/4 my-1 p-2">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Customer Name</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="this.$store.state.data.customer"
                @change="getEntityList">
                <option :value="this.$store.state.data.customer" selected>
                  {{ this.$store.state.data.customer }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="this.$store.state.data.customer">
              <label for="flatfileSource" class="block uppercase text-xs font-light mb-2">Select Source</label>
              <input readonly type="text" id="flatfileSource" v-model="well_info.source"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" />
            </div>
            <div class="w-1/5 my-1 p-2" v-if="well_info.source">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Enter Latitude</label>
              <input required type="text" v-model.number="well_info.latitude" id="latiInputId"  @input="validation($event, 1)" @change="checkWellInfo"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />
              <!-- <span class="show_hide icon is-small lasfile_btn validate_check">
                      <i class="fas fa-check"></i>
                    </span> -->
            </div>
            <div class="w-1/5 my-1 p-2" v-if="well_info.latitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Enter Longitude</label>
              <input required type="text" id="longiInputId" v-model.number="well_info.longitude" @change="checkWellInfo"  @input="validation($event, 2)"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />
              <!-- <span class="show_hide icon is-small lasfile_btn validate_check">
                      <i class="fas fa-check"></i>
                    </span> -->
            </div>
            <div class="w-1/8 my-1 p-2" v-if="well_info.latitude && well_info.longitude">
              <button @click="validate_latitude" class="toggleLabel p-1 px-2 rounded flex items-center mt-6">
                validate
              </button>
              
            </div>
            <div class="w-1/10 my-1 p-2" v-if="well_info.longitude && !wellmatched">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select TimeZone</label>
              <select class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="well_info.timeZone"
                @change="getTimeZoneList">
                <option v-for="(timeZone, i) in timeZoneListData" :key="i" :value="timeZone">
                  {{ timeZone }}
                </option>
              </select>
            </div>
            
            <div class="w-1/12 my-1 p-2" v-if="!wellmatched && well_info.timeZone && well_info.latitude && well_info.longitude">
              <label for="dstOffset" class="block uppercase text-xs font-light mb-2">DST Offset</label>
              <input required type="text" v-model="well_info.dstOffset" id="dstOffset"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />

            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.timeZone && well_info.latitude && well_info.longitude">
              <label for="wellNameInput" class="block uppercase text-xs font-light mb-2">Select WellName</label>
              <input required type="text" v-model="well_info.wellName" @keydown="validateSpecialCharacter(well_info.wellName, 'wellName')"
               id="wellNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''"  />
              <span v-if="charValidation !== ''">
                {{charValidation}}
              </span>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellName && well_info.latitude && well_info.longitude && charValidation === ''">
              <label for="wellBoreNameInput" class="block uppercase text-xs font-light mb-2">Select Wellbore
                Name</label>
              <input required type="text" v-model="well_info.wellBoreName" id="wellBoreNameInput" @keydown="validateSpecialCharacter(well_info.wellBoreName, 'wellBore')"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" :class="true ? 'validated_latlong' : ''" />
                <span v-if="charValidationwb !== ''">
                  {{ charValidationwb }}
                </span>
            </div>
            <div class="w-1/6 my-1 p-2" v-if="well_info.wellBoreName && well_info.latitude && well_info.longitude && charValidationwb === ''">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Enter Rigname</label>
              <input required type="text" id="longiInputId" v-model="well_info.rigName"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" @focusin="rigNameFocusHandler" />
            </div>
            <div class="w-1/8 my-1 p-2" v-if="well_info.rigName && well_info.latitude && well_info.longitude">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">Select Wellprofile</label>
              <select required type="text" id="customerNameInput"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" v-model="well_info.wellProfile"
                @change="wellProfileSelectHandler">
                <option :value="wellProfile" v-for="(wellProfile, index) of wellProfileList" :key="index">
                  {{ wellProfile }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellProfile">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">SUB SURF LAT</label>
              <input required type="text" id="longiInputId" v-model.number="well_info.sublat"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" />
            </div>
            <div class="w-1/4 my-1 p-2" v-if="well_info.wellProfile">
              <label for="customerNameInput" class="block uppercase text-xs font-light mb-2">SUB SURF LONG</label>
              <input required type="text" id="longiInputId" v-model.number="well_info.sublong"
                class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text newSty_bg" />
            </div>
            <div class="w-1/4 my-1 p-2 flex" v-if="well_info.wellProfile && !wellmatched">
              <div class="w-2/4">
                <label for="selectStream" class="block uppercase text-xs font-light mb-2 newSty_bg">Select stream</label>
                <div class="flex mt-2">
                  <div class="flex items-center mr-4">
                    <input type="radio" value="live" v-model="well_info.wellstatus" name="streem" id="streemLive"
                      @click="onChangeSelection_Live_histo" :checked="section == 3" :disabled="section == 3 || section == 2" />
                    <label class="ml-2 uppercase text-xs font-light" for="streemLive" :disabled="well_info.wellstatus !== 'LIVE' || section == 3 && section == 2">Live</label>
                  </div>
                  <div class="flex items-center" v-if="section != 3">
                    <input type="radio" value="Historical" v-model="well_info.wellstatus" name="streem" id="streemHist" :checked="well_info.wellstatus === 'Historical'" :disabled="well_info.wellstatus !== 'Historical'" />
                    <label class="ml-2 uppercase text-xs font-light" for="streemHist" :disabled="well_info.wellstatus !== 'Historical'">Historical</label>
                  </div>
                </div>
              </div>
              <div class="w-2/4" v-if="well_info.wellstatus && !wellmatched">
                <label for="drillingProfile" class="block uppercase text-xs font-light mb-2">Select Drilling
                  Profile</label>
                <div class="flex mt-2">
                  <div class="flex items-center mr-4">
                    <input type="radio" value="Offshore" v-model="well_info.drillProfile" name="drillingprofile"
                      id="Offshore" @click="onChangeSelection_Live_histo" />
                    <label class="ml-2 uppercase text-xs font-light" for="Offshore">Offshore</label>
                  </div>
                  <div class="flex items-center">
                    <input type="radio" value="Onshore" name="drillingprofile" v-model="well_info.drillProfile"
                      id="Onshore" />
                    <label class="ml-2 uppercase text-xs font-light" for="Onshore">Onshore</label>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="px-1 mt-4 flex justify-center shadow" v-if="!wellmatched">
            <button v-if="!wellmatched && well_info.drillProfile && charValidationwb === ''" class="dark_text p-2  mt-4 uppercase ml-2 newSty_bg"
              @click="loader ? '' : submitFlatFileWellData()">
              SUBMIT
            </button>
            <button class="dark_text p-2  mt-4 uppercase ml-2  shadow newSty_bg" @click="previewLocation()"
              v-if="well_info.latitude && well_info.longitude && !wellmatched">
              {{ !prevLocation ? 'Preview' : 'Hide' }} Location
            </button>
          </div>
          <div class="px-1 mt-4 flex justify-center" v-if="prevLocation">
            <iframe
              :src="`https://maps.google.com/maps?q=${well_info['latitude']}, ${well_info['longitude']}&z=15&output=embed`"
              height="400" frameborder="0" style="border: 0; width: 100%"></iframe>
          </div>
          <div class="px-1 mt-4 flex justify-center text-red-500" v-if="wellmatched">
            Above Entered Latitude & Longitude Details are Already added
            <span>
              <p class="curser-pointer ml-1 activenav text-xs uppercase" @click="previewLocation()">
                {{ !prevLocation ? 'View' : 'Close' }}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Flat file tab related end -->
    <div class="flex items-center justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0 absolute w-full" :class="iframeActive ? 'visible' : 'hidden'"
      >
        
                  <iframe class="relative" ref="myIframe" width="600" height="400" frameborder="0" style="height: 92vh; width: 100%;"></iframe>                
                
      </div>
      <Loader v-if="loader" />
  </div>
</template>
<script>
import axios from "axios";
import Axios from 'axios'
import API_SERVICE from "../../../api/services"
// import config from "../../../config";
import constant from "../../../api/constant";
import URL from "../../../config";
import jwt_decode from "jwt-decode";
import Loader from "../../../layouts/Loader";
export default {
  emits: [
    "toggle_management"
  ],
  data() {
    return {
      isAdmin: JSON.parse(localStorage.getItem("userRole"))?.roleName.toLowerCase().replace(/\s/g, ""),
      userDetails: [],
      getSourceList: [],
      well_info: {
        wellName: '',
        wellBoreName: '',
        rigName: '',
        longitude: '',
        latitude: '',
        srcWellId: '',
        srcWellBoreId: '',
        wellProfile: '',
        drillProfile: '',
        source: '',
        wellstatus: '',
        timeZone: '',
        sublong: '',
        sublat: '',
        dstOffset:'',
        charValidation:'',
        charValidationwb:''
      },
      timeZoneListData: {},
      sourceWellData: [],
      wellBoreList: {},
      selectedWellDetails: {},
      selectedWellboreDetails: {},
      wellProfileList: {},
      prevLocation: false,
      wellmatched: false,
      section: 0,
      allFieldsFilled:false,
      offsetDelta:0,
      loader: false,
      iframeActive:false
      // specialChar: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
    };
  },
  props: {
    wellboreId: {
      type: String,
      default: ""
    },
    wellIdProp: {
      type: String,
      default: ""
    }
  },
  components: {
    Loader
  },
  computed: {

  },
  methods: {
    toUpdateWellList(){
      this.$store.dispatch("data/setCustomer", { customer:this.$store.state.data.customer, isInitial: true })
    },
    selectSection(section) {
      this.section = section;
      this.well_info = []
      // this.well_info.wellstatus = '';
      console.log('______well_data_____', this.well_info)
      if (section == 2) {
        this.well_info.source = 'flat_file';
        this.well_info.wellstatus = 'HISTORICAL';
        this.getTimeZoneList();
      }if (section == 3){
        this.well_info.sourceType = 'wits0'
        this.well_info.source = 'wits0';
        this.well_info.wellstatus = 'LIVE';
        this.getTimeZoneList();
      }else{
        axios.get(constant.DATASERVICES_URL + `witsml/wells?entityid=${this.userDetails.entity_id ? this.userDetails.entity_id : this.userDetails}&source=${this.well_info.source}`).then(resp => {
          this.sourceWellData = resp.data
          this.getTimeZoneList()
        })
        // this.chooseSource(this.well_info.source)
        this.well_info.wellstatus = this.sourceWellData.wellstatus
        this.well_info.sourceType = 'witsml'
        console.log('_________well_status_________', this.well_info)
      }
    },
    async getCustomerDetails() {
      const customerData = await localStorage.getItem('selectedEntityId')
      console.log('___checking___userDetailservice___', customerData)
      // this.$serviceHelpers.getDetails("apollodart").user
      this.userDetails = customerData
      console.log('______user_selected__customer___', this.$store.state)
      const getSourceList = await axios.get(constant.DATASERVICES_URL + `witsmlsource/sources?entityid=${customerData}&status=A`)
      this.getSourceList = getSourceList.data
    },
    async chooseSource(source) {
      try {
        await axios.get(constant.DATASERVICES_URL + `witsml/wells?entityid=${this.userDetails.entity_id ? this.userDetails.entity_id : this.userDetails}&source=${source.target.value}`).then(resp => {
          this.sourceWellData = resp.data
          this.getTimeZoneList()
        })
      } catch (error) {
        console.log(error)
      }
    },
    selectedWellName(selectedWell) {
      // this.updateAllFieldsFilled()
      this.selectedWellDetails = selectedWell
      this.well_info.wellstatus = selectedWell.wellstatus
      this.well_info.wellName = selectedWell.wellname
      this.well_info.srcWellId = selectedWell.wellid
      this.wellBoreList = selectedWell.wellbores
    },
    selectedWellBore(wellbore) {
      this.selectedWellboreDetails = wellbore
      this.well_info.wellBoreName = wellbore.wellborename
      this.well_info.srcWellBoreId = wellbore.wellboreid
      console.log('_____________storeData___', wellbore)

      this.getWellProfile()
    },
    async getWellProfile() {
      try {
        await axios.get(constant.DATASERVICES_URL + `miscellaneous/type/values?type=WELLPROFILES`).then(profile => {
          this.wellProfileList = profile.data
        })
      } catch (error) {
        console.log(error)
      }

    },
    submitWellData() {
      this.loader = true
      var tempWellData = {
        "wellInfoId": null,
        "customerName": this.$store.state.data.customer,
        "wellId": null,
        "wellName": this.well_info.wellName,
        "wellBoreName": this.well_info.wellBoreName,
        "rigName": this.well_info.rigName,
        "longitude": this.well_info.longitude,
        "latitude": this.well_info.latitude,
        "srcWellId": this.well_info.srcWellId,
        "srcWellBoreId": this.well_info.srcWellBoreId,
        "sidetrackWellname": "",
        "archived": null,
        "wellStatus": 1,
        "wellProfile": this.well_info.wellProfile,
        "drillingType": this.well_info.drillProfile,
        "pdfWellname": "",
        "multiCustomer": [this.$store.state.data.customer],
        "cloudSrcLoc": this.well_info.source,
        "sourceType": this.well_info.sourceType,
        "feedType": this.well_info.wellstatus === 'LIVE' ? 'live' : (this.well_info.wellstatus === 'HISTORICAL' ? 'Historical' : ''),
        "time": null,
        "subSurfLong": 0,
        "subSurfLat": 0,
        "timeZone": this.well_info.timeZone,
        "dstOffset" : this.well_info.dstOffset
      }
      console.log('_____________storeData___tempWellData', tempWellData)
      try {
        axios.post(constant.DATASERVICES_URL + 'wellinfo/saveorupdate', tempWellData).then(saved => {
          if (saved.data) {

            this.$toast.success('Well added successfully')
            this.loader = false
            this.$emit('add_well_pop');
            this.toUpdateWellList();
          }
        })
      } catch (error) {
        this.loader = false
        this.$toast.error(error)
      }
    },
    async submitFlatFileWellData() {
      this.loader = true
      const flatfileWellData = {
        "wellInfoId": null,
        "customerName": this.$store.state.data.customer,
        "wellId": null,
        "wellName": this.well_info.wellName,
        "wellBoreName": this.well_info.wellBoreName,
        "rigName": this.well_info.rigName,
        "longitude": this.well_info.longitude,
        "latitude": this.well_info.latitude,
        "srcWellId": null,
        "srcWellBoreId": null,
        "sidetrackWellname": "",
        "archived": null,
        "wellStatus": 1,
        "wellProfile": this.well_info.wellProfile,
        "drillingType": this.well_info.drillProfile,
        "pdfWellname": "",
        "multiCustomer": [this.$store.state.data.customer],
        "cloudSrcLoc": null,
        "sourceType": this.well_info.source,
        "feedType": this.well_info.wellstatus === 'LIVE' ? 'live' : (this.well_info.wellstatus === 'HISTORICAL' ? 'Historical' : ''),
        "time": null,
        "subSurfLong": this.well_info.sublong,
        "timeZone": this.well_info.timeZone,
        "dstOffset" : this.well_info.dstOffset,
        "entityId" : localStorage.getItem("selectedEntityId")
      }
      try {
        axios.post(constant.DATASERVICES_URL + 'wellinfo/saveorupdate', flatfileWellData).then(saved => {
          if (saved.data) {
            console.log('____well__added__data___', saved.data[0])
            this.loader = false
            if (saved.data[0].sourceType === 'wits0') {
              this.loader = true
              this.$toast.success('Well added successfully. Navigating to WITS mapping page...');

              // this.$emit('add_well_pop');
              setTimeout(() =>{ 
              const { access_token, refresh_token } = this.$serviceHelpers.getDetails("670023242:az");
              // const { access_token, refresh_token } = this.$serviceHelpers.getDetails("670023242:az");
              const token = jwt_decode(access_token);
              const isExpired = new Date() <= new Date(token.exp * 1000);
              if (!isExpired) {
                const response = axios.post(
                  URL.HOST_URL.SERVER_URL + dictionary.auth.REFRESH_TOKEN,
                  {
                    token: refresh_token,
                  }
                );
                console.log(typeof response);
                const newTokenDet = response.data;
                if (newTokenDet && newTokenDet.access_token) {
                  //update the localstorage
                  this.$serviceHelpers.setDetails("670023242:az", newTokenDet);
                  this.iframeActive = true
                  this.$refs.myIframe.src = (`${URL.HOST_URL.ADMINISTRATOR_URL}witsMapping?dfc364ca-86b9-5162-9726-79c48cf0d0c4=${JSON.stringify(this.$serviceHelpers.getDetails("y:a"))}&abcbf877-edea-5d5b-91c3-a5bba8374a10=${JSON.stringify(newTokenDet)}&abcbf888-edea-5d5b-91c3-a5bba8374b11=${JSON.stringify(saved.data)}&entityId=${localStorage.getItem("selectedEntityId")}`);
                  console.log("Access token is not valid");
                } else {
                  console.log("Access token err", newTokenDet);
                  this.$toast.error(`Token is Invalid Please Login to proceed!`, {
                    duration: "2000",
                    position: "top",
                    pauseOnHover: true,
                  });
                  this.logout();
                }
              } else {
                console.log("Access token is valid");
                this.iframeActive = true

                this.$refs.myIframe.src = (`${URL.HOST_URL.ADMINISTRATOR_URL}witsMapping?dfc364ca-86b9-5162-9726-79c48cf0d0c4=${JSON.stringify(
                    this.$serviceHelpers.getDetails("y:a")
                  )}&abcbf877-edea-5d5b-91c3-a5bba8374a10=${JSON.stringify(
                    this.$serviceHelpers.getDetails("670023242:az")
                  )}&abcbf888-edea-5d5b-91c3-a5bba8374b11=${JSON.stringify(saved.data)}&entityId=${localStorage.getItem("selectedEntityId")}`
                );
console.log('______ref_id_iframe___', this.$refs.myIframe.src)
              }
      
              // {window.open(URL.HOST_URL.ADMINISTRATOR_URL +  'witsMapping', '_target');
              this.loader = false
              }, 2000);
            } else {
              this.$toast.success('Well added successfully');
              this.$emit('add_well_pop');
              
            }
            this.toUpdateWellList();
            
          }

        })
      } catch (error) {
        this.loader = false
        this.$toast.error(error)
      }
    },
    previewLocation() {
      this.prevLocation = !this.prevLocation
    },
    async getTimeZoneList() {
      try {
        const timeZoneList = await Axios.get('https://worldtimeapi.org/api/timezone')
        this.timeZoneListData = timeZoneList.data
        console.log('_____customer___timezone______', this.timeZoneListData)
        if (this.well_info.timeZone) {
          console.log("coming here in selct");
          // this.isValidateDis = false
          this.source_histo_selected = true
          // this.updateAllFieldsFilled()

        }
      } catch (error) {
        console.log(error)
      }
    },
    checkWellInfo() {
      var wellLocation = {
        latitude: this.well_info.latitude,
        longitude: this.well_info.longitude
      }
      try {
        axios.post(constant.DATASERVICES_URL + 'wellinfo/search', wellLocation).then(mateched => {
          if (mateched.data.length > 0) {
            this.wellmatched = true
          } else {
            this.wellmatched = false
          }
        })
      } catch (error) {

      }
    },
    rigNameFocusHandler() {
      this.getWellProfile();
    },
    wellProfileSelectHandler() {
      if (this.section == 2) {
        this.well_info.wellstatus = 'Historical';
      }
    },
    validation(event, num) {
      // this.updateAllFieldsFilled()
      // Remove all characters that are not digits or dots
      event.target.value = event.target.value.replace(/[^0-9\.-]/g, '');
      // Replace multiple dots with a single dot
      event.target.value = event.target.value.replace(/(\..*)\./g, '$1');
      // Replace multiple - with a single -
      event.target.value = event.target.value.replace(/(\-.*)\-/g, '$1');
      // To allow - only at first position
      if (event.target.value.indexOf('-') > 0) {
        event.target.value = event.target.value.replaceAll('-', '');
      }
      let indexOfDot = event.target.value.replace(/[^0-9\.]/g, '').indexOf('.')
      if (indexOfDot > 3) {
        event.target.value = event.target.value.replaceAll('.', '');
      }
      if (event.target.value.indexOf('.') == '-1' && event.target.value.length > 3 && !event.target.value.includes('-')) {
        event.target.value = event.target.value.slice(0, 3) + '.' + event.target.value.slice(3)
      }
      else if (event.target.value.indexOf('.') == '-1' && event.target.value.length > 4 && event.target.value.includes('-')) {
        event.target.value = event.target.value.slice(0, 4) + '.' + event.target.value.slice(4)
      }
      indexOfDot = event.target.value.indexOf('.')
      let afterDotValues = event.target.value.slice(indexOfDot + 1).length
      if (afterDotValues > 8) {
        let roundedToEightDigits = '';
        if (!event.target.value.includes('-')) {
          roundedToEightDigits = Math.floor(parseFloat(event.target.value) * 100000000) / 100000000;
        }
        else {
          roundedToEightDigits = Math.ceil(parseFloat(event.target.value) * 100000000) / 100000000;
        }
        event.target.value = roundedToEightDigits.toString();
      }

      if (num === 1) { this.well_info.latitude = event.target.value }
      else if (num === 2) { this.well_info.longitude = event.target.value }
      else if (num === 3) { this.latitude = event.target.value }
      else if (num === 4) { this.well_info.longitude = event.target.value }
      else if (num === 5) { this.sigleSideTrack.latitude = event.target.value }
      else if (num === 6) { this.sigleSideTrack.longitude = event.target.value }
    },
    updateAllFieldsFilled() {
      // Check if all mandatory fields have a value
      // console.log('_____entered_checkss_______', this.well_info.longitude)
      // if(this.well_info.longitude.length <=0  || this.well_info.latitude.length <=0 || this.well_info.wellName.length <=0 ||
      //   this.well_info.wellBoreName.length <=0 || this.well_info.rigName.length <=0 || this.well_info.wellProfile.length <=0 || this.well_info.timeZone.lenght <=0){
      //   this.allFieldsFilled = false
      // }else{
      //   this.allFieldsFilled = true

      // }
      // Add more conditions for additional fields as needed
    },
    validate_latitude(){
      try {
        Axios.get(`https://api.geotimezone.com/public/timezone?latitude=${this.well_info.latitude}&longitude=${this.well_info.longitude}`, {
            headers: { 
              Authorization : {}
              // Omitting the Authorization header
            }}
          ).then(timeZoneJson=>{
        // Axios.get(`http://api.geonames.org/timezoneJSON?lat=${this.well_info.latitude}&lng=${this.well_info.longitude}&username=aslamsk`).then(timeZoneJson=>{
        // Axios.get(`https://api.timezonedb.com/v2.1/get-time-zone?key=GXUW8KHU6ZXY&format=json&by=position&lat=${this.well_info.latitude}&lng=${this.well_info.longitude}`).then(timeZoneJson=>{
          console.log('___timeZoneJson___', timeZoneJson.data)
          if (timeZoneJson.data.status && timeZoneJson.data.status.message) {
            this.$toast.info(timeZoneJson.data.status.message);
            }else{
              this.well_info.timeZone = timeZoneJson.data.iana_timezone
              this.well_info.dstOffset = timeZoneJson.data.dst_offset !== null ? timeZoneJson.data.dst_offset : 0
              }
          // var isTimeZoneis = Axios.get(`https://worldtimeapi.org/api/timezone/${this.well_info.timeZone}`)
          // this.isTimeZoneis = isTimeZoneis
          // console.log('___selected_time_zone_is___', this.isTimeZoneis)
        })
      } catch (error) {
        console.log(error)
      }
    },
    validateSpecialCharacter(character, wellType) {
    const regex = /^[a-zA-Z0-9]+$/; // Does not allow spaces at start or end
    if(wellType === 'wellName'){
    console.log("____character____", character);
    if (!regex.test(character)) {
      this.isValid = true;
      this.charValidation = "Special characters not allowed";
      this.well_info.wellName = this.well_info.wellName.trim(); // Trim spaces
      this.submitOFF = true;
    } else {
      this.charValidation = "";
    }
    }if(wellType === 'wellBore'){
      if (!regex.test(character)) {
      this.isValid = true;
      this.charValidationwb = "Special characters not allowed";
      this.well_info.wellBoreName = this.well_info.wellBoreName.trim(); // Trim spaces
      this.submitOFF = true;
    } else {
      this.charValidationwb = "";
    }
    }
  },
  handleIframeMessage(event) {
    // Check if the message is from the iframe and if it indicates the submit button was clicked
    if (event.source === this.$refs.myIframe.contentWindow && event.data === 'wits-mapping-completed') {
      // Close the iframe or perform any other actions
      this.closeIframe();
      this.$emit('add_well_pop');
    }
    },
      closeIframe() {
        // Set iframeActive to false or perform any other logic to close/hide the iframe
        this.iframeActive = false;
      this.$emit('toggle_management')

      }
  },
  beforeMount() {
    // this.getEntityList();
    // this.getEntityDetails({ target: { value: 1 } });
  },
  beforeUnmount() {
    this.root.style.setProperty("--higherZIndex", 10000);
  },
  mounted() {
   console.log('______router__details_____', this.$route.query)
   window.addEventListener('message', this.handleIframeMessage);
  //  this.$emit('add_well_pop');
    this.root = document.querySelector(":root");
    this.iframeActive = false
    this.root.style.setProperty("--higherZIndex", 0);
    if (this.$store.state.data.customer) {
      this.getCustomerDetails()
      this.selectSection(1)
    } else {
      this.$toast.error('please choose customer')
    }
  },
  beforeDestroy() {
  // Remove the event listener to avoid memory leaks
    window.removeEventListener('message', this.handleIframeMessage);
  }
};
</script>
<style scoped>
.top_section {
  height: 5rem;
}

.management_bg {
  /* background: var(--popupBg); */
  background: var(--root_nav);
  color: var(--textColor);
  font-size: 0.7rem;
}

.font-light {
  color: var(--textColor);
}

.dark_text {
  background: var(--sidebarbg);
  color: var(--textColor);

}

.dark_text_heading {
  color: var(--textColor);
  font-size: 1.2rem;
  font-weight: 600;
}

.dark_primary {
  background: var(--sidebarbg);
  color: var(--textColor);

}

tr {
  background: var(--sidebarbg);
  color: var(--textColor);

  font-size: 0.8rem;
}

td {
  padding: 5px;
}

.active_button {
  background: var(--activeTextColor);
  color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin-top: 40.5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.1px grey;
  border-radius: 10px;
  margin-top: 40.5px;
  height: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #63cdff;
  border-radius: 10px;
  height: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #63cdff;
  height: 2px;
}

.list_heading {
  position: fixed;
  width: 26.1%;
}

.list_group {
  height: 80vh;
  margin-bottom: 5vh;
  overflow-y: auto;
}

.list_group_left {
  height: 80vh;
  margin-bottom: 0;
}

.list_group_rem {
  height: 90vh;
  margin-bottom: 5vh;
}

.list_ltems:nth-child(2) {
  margin-top: 40.5px;
}

.list_skull {
  margin-top: 40.5px;
}

.common_text {
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.8vmin);
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) #479669;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.modal_prop {
  height: 80vh;
  overflow-y: scroll;
}

.soln_tr {
  background-color: #1c1d1f;
  cursor: not-allowed;
}

.soln_td {
  border: 1px solid white !important;
}

label {
  margin-bottom: 0;
}

thead th {
  position: sticky;
  top: 0;
  font-size: calc(0.3vw + 0.3vh + 0.8vmin);
  background: var(--central_bg);
  border: 0.3px solid #525252;
}

.modal_show {
  z-index: 9999;
}

tr td {
  white-space: wrap;
  text-align: center;
  border: 0.3px solid #525252;
}

.header:first-child {
  /* background-color: #d3d3d3 ; */
  left: 0;
  position: sticky;
  padding: 0 5px;
  z-index: 9999;
}

.header_body:first-child {
  background-color: #1c1d1f;
  padding: 0 5px;
  left: 0;
  position: sticky;
  z-index: 999;
}

.table_container {
  border: 1px solid gray;
  overflow: auto;
  height: 90vh;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.spin_loader_ {
  margin: 0 auto;
  /* width: 4% !important; */
  /* position: fixed; */
  top: 50%;
  left: 50%;
}

.spinner_text {
  margin: 0 auto;
  /* width: 400px; */
  /* position: fixed; */
  top: 58%;
  font-size: 12px;
  left: 46%;
}

.button_font {
  /* font-size: 10px; */
  font-size: calc(0.3vw + 0.3vh + 0.8vmin);
}

/* // toaster style */
.v-toast {
  font-size: 12px;
}

.v-toast__icon {
  font-size: 12px;
}

.v-toast__item {
  display: flex;
  flex-direction: row-reverse;
}

.v-toast__icon {
  opacity: 0.9 !important;
  height: 15px !important;
}

/* .fas{
    transition: .9s;
  } */
.tooltip_font {
  font-size: 7px;
}

.well_animate {
  transition: 2s;
}

.lithology_preview {
  height: 20px
}

td input {
  background: none !important;
}

.close_button {
  color: red;
  font-size: 1rem;
}

select {
  border: none;
}
</style>
